import {
  ChatSequence,
  ChatSequenceView,
  createBaseCssVariablesStream,
} from "@xflr6/chatbot";
import {
  getFlowSequenceIdFromUid,
  getOrCreateManagedChatSequence,
  getPublishedFlowSequence,
  updateManagedChatSequence,
} from "@xflr6/chatbot-api";
import React, { ReactElement, useEffect, useRef, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";

import { startPromptKey } from "../../chatbotSetup";
import {
  applyThemeSettings,
  extractChatSequenceSettings,
  extractIdentifierType,
  extractLanguage,
  getTranslatedName,
  MIN_NEXT_PROMPT_DELAY_MS,
} from "../../utils";
import styles from "./ChatSeqViewPage.module.css";

function postChatEndedMessage(id: string, score: number | null) {
  window.parent.postMessage(
    {
      eventType: "chatEnded",
      detail: { type: "chat_sequences", id, score },
    },
    "*"
  );
}

export default function ChatSeqViewPage(): ReactElement | null {
  const { id: _id, identifier: rawIdentifier } = useParams<{
    id: string;
    identifier: string | undefined;
  }>();
  const identifier = rawIdentifier
    ? decodeURIComponent(rawIdentifier)
    : undefined;
  const history = useHistory();
  const [chatSequence, setChatSequence] = useState<ChatSequence>();
  const location = useLocation();
  const settings = extractChatSequenceSettings(location.search);
  const type = extractIdentifierType(location.search);
  const language = extractLanguage(location.search);

  const updateToken = useRef<string>();
  const $baseCssVariables = useRef(createBaseCssVariablesStream());
  const [avatarUrl, setAvatarUrl] = useState<string | null | undefined>();

  async function initDryRunChatSequence() {
    const id = _id.includes("-")
      ? (await getFlowSequenceIdFromUid(_id)).toString()
      : _id;
    const serverResponse = await getPublishedFlowSequence(id);
    applyThemeSettings($baseCssVariables.current, serverResponse.settings);
    setAvatarUrl(serverResponse.settings.avatarUrl);
    const chatSequence_ = new ChatSequence(
      getTranslatedName(
        serverResponse.name,
        serverResponse.tName,
        language ?? serverResponse.language
      ),
      {
        items: serverResponse.flows.map((flow) => ({
          label: getTranslatedName(
            flow.name,
            flow.tName,
            language ?? serverResponse.language
          ),
          promptKey: startPromptKey(flow.id, flow.version).toString(),
        })),
      },
      {
        onPromptAdded: (index, prompt) => {
          if (prompt.isTerminal && index === serverResponse.flows.length - 1) {
            postChatEndedMessage(id, chatSequence_.totalScore);
          }
        },
        language: language ?? serverResponse.language,
        chatOptions: {
          defaultMinNextPromptDelayMs: MIN_NEXT_PROMPT_DELAY_MS,
          context: {
            userId: identifier,
            args: {
              originId: [id],
            },
          },
        },
      }
    );
    if (settings.disableAutoAnswer) {
      chatSequence_.isAutoAnswerEnabled = false;
    }
    setChatSequence(chatSequence_);
    chatSequence_.setCurrentIndex(0);
  }

  async function initLiveRunChatSequence() {
    const id = _id.includes("-")
      ? (await getFlowSequenceIdFromUid(_id)).toString()
      : _id;
    const serverResponse = await getOrCreateManagedChatSequence(
      id,
      identifier,
      type
    );
    if (identifier == null) {
      history.replace(
        `/chat_sequences/${_id}/${encodeURIComponent(
          serverResponse.identifier
        )}${location.search}`
      );
    } else {
      applyThemeSettings($baseCssVariables.current, serverResponse.settings);
      setAvatarUrl(serverResponse.settings.avatarUrl);
      const flowSequence = serverResponse.flowSequence;
      const chatSeqHistory = serverResponse.history.histories;
      const chatSequence_ = new ChatSequence(
        getTranslatedName(
          flowSequence.name,
          flowSequence.tName,
          language ??
            chatSeqHistory?.[0]?.language ??
            serverResponse.flowSequence.language
        ),
        {
          items: flowSequence.flows.map((flow, index) => ({
            label: getTranslatedName(
              flow.name,
              flow.tName,
              language ??
                chatSeqHistory?.[0]?.language ??
                serverResponse.flowSequence.language
            ),
            promptKey: startPromptKey(flow.id, flow.version).toString(),
            initialHistory: chatSeqHistory[index],
          })),
        },
        {
          onHistoryItemWillBeInserted: (index, item, prompt) => {
            if (item.direction === "sent" && prompt.custom?.feedback) {
              item.custom = { feedback: true };
            }
          },
          onHistoryChanged: (index, history) => {
            const lastItem = history[history.length - 1];
            if (
              updateToken.current != null &&
              lastItem !== null &&
              lastItem.direction === "received" &&
              // Don't save for auto prompts, otherwise it leads to many save
              // requests happening really fast, which can subsequently lead to
              // the updateToken getting out of sync.
              lastItem.promptAnswerType !== "auto"
            ) {
              updateManagedChatSequence(
                id,
                identifier,
                index,
                chatSequence_.chats[index].toJson(),
                updateToken.current
              )
                .then((response) => {
                  updateToken.current = response.updateToken;
                })
                .catch((e) => {
                  if (e.response.status === 409) {
                    window.alert(
                      "This page needs a refresh to stay up to date." +
                        "\nPress 'OK' to continue."
                    );
                    window.location.reload();
                  }
                });
            }
          },
          onPromptAdded: (index, prompt) => {
            if (prompt.isTerminal && index === flowSequence.flows.length - 1) {
              postChatEndedMessage(id, chatSequence_.totalScore);
            }
          },
          language:
            language ??
            chatSeqHistory?.[0]?.language ??
            serverResponse.flowSequence.language,
          chatOptions: (index) => ({
            defaultMinNextPromptDelayMs:
              serverResponse.settings.chatDefaultDelayMs ??
              MIN_NEXT_PROMPT_DELAY_MS,
            confirmDestructiveAlteration: () =>
              new Promise((resolve) => {
                if (
                  window.confirm(
                    "Progress after this point in the chat will be lost." +
                      "\nAre you sure you want to go ahead?"
                  )
                ) {
                  resolve(true);
                } else {
                  resolve(false);
                }
              }),
            context: {
              userId: identifier,
              args: {
                originId: [flowSequence.flows[index].id],
              },
            },
          }),
        }
      );
      if (settings.disableAutoAnswer) {
        chatSequence_.isAutoAnswerEnabled = false;
      }
      setChatSequence(chatSequence_);
      updateToken.current = serverResponse.updateToken;
      if (chatSeqHistory != null) {
        const lastNonNull = chatSeqHistory.findIndex(
          (_, i) => chatSeqHistory?.[i + 1] == null
        );
        chatSequence_.setCurrentIndex(lastNonNull);
      } else {
        chatSequence_.setCurrentIndex(0);
      }
    }
  }

  useEffect(() => {
    if (settings.dryRun) {
      initDryRunChatSequence();
    } else {
      initLiveRunChatSequence();
    }

    return () => chatSequence?.dispose();
  }, [_id, identifier, location.search]);

  if (chatSequence == null) return null;

  return (
    <div className={styles.root}>
      {settings.showName && (
        <div className={styles.title}>{chatSequence.name}</div>
      )}
      <div className={styles.chatSequenceView}>
        <ChatSequenceView
          chatSequence={chatSequence}
          showScore={settings.showScore}
          allowRandomAccess={settings.allowRandomAccess}
          disableKeyboardAnswer={settings.disableKeyboardAnswer}
          onChatSeqStateError={console.log}
          onCurrentChatStateError={console.log}
          $baseCssVariables={$baseCssVariables.current}
          avatarSrc={avatarUrl}
        />
      </div>
    </div>
  );
}
