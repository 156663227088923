import { Chat, ChatView, createBaseCssVariablesStream } from "@xflr6/chatbot";
import {
  getFlowIdFromUid,
  getOrCreateManagedChat,
  getPublishedFlow,
  updateManagedChat,
} from "@xflr6/chatbot-api";
import React, { ReactElement, useEffect, useRef, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";

import { startPromptKey } from "../../chatbotSetup";
import {
  applyThemeSettings,
  extractChatSettings,
  extractIdentifierType,
  extractLanguage,
  getTranslatedName,
  MIN_NEXT_PROMPT_DELAY_MS,
} from "../../utils";
import styles from "./ChatViewPage.module.css";

function postChatEndedMessage(id: string, score: number | null) {
  window.parent.postMessage(
    {
      eventType: "chatEnded",
      detail: { type: "chats", id, score },
    },
    "*"
  );
}

export default function ChatViewPage(): ReactElement | null {
  const { id: _id, identifier: rawIdentifier } = useParams<{
    id: string;
    identifier: string | undefined;
  }>();
  const identifier = rawIdentifier
    ? decodeURIComponent(rawIdentifier)
    : undefined;
  const history = useHistory();
  const [chat, setChat] = useState<Chat>();
  const location = useLocation();
  const settings = extractChatSettings(location.search);
  const type = extractIdentifierType(location.search);
  const language = extractLanguage(location.search);

  const updateToken = useRef<string>();
  const $baseCssVariables = useRef(createBaseCssVariablesStream());
  const [avatarUrl, setAvatarUrl] = useState<string | null | undefined>();

  async function initDryRunChat() {
    const id = _id.includes("-")
      ? (await getFlowIdFromUid(_id)).toString()
      : _id;
    const serverResponse = await getPublishedFlow(id, language);
    applyThemeSettings($baseCssVariables.current, serverResponse.settings);
    setAvatarUrl(serverResponse.settings.avatarUrl);
    const chat_ = new Chat(
      getTranslatedName(
        serverResponse.name,
        serverResponse.tName,
        language ?? serverResponse.language
      ),
      {
        defaultMinNextPromptDelayMs: MIN_NEXT_PROMPT_DELAY_MS,
        onPromptAdded: (prompt) => {
          if (prompt.isTerminal) {
            postChatEndedMessage(id, chat_.totalScore);
          }
        },
        context: {
          userId: identifier,
          args: {
            originId: [id],
          },
        },
        language: language ?? serverResponse.language,
      }
    );
    if (settings.disableAutoAnswer) {
      chat_.isAutoAnswerEnabled = false;
    }
    setChat(chat_);
    await chat_.setPrompt(startPromptKey(id));
  }

  async function initLiveRunChat() {
    const id = _id.includes("-")
      ? (await getFlowIdFromUid(_id)).toString()
      : _id;
    const serverResponse = await getOrCreateManagedChat(id, identifier, type);
    if (identifier == null) {
      history.replace(
        `/chats/${_id}/${encodeURIComponent(serverResponse.identifier)}${
          location.search
        }`
      );
    } else {
      applyThemeSettings($baseCssVariables.current, serverResponse.settings);
      setAvatarUrl(serverResponse.settings.avatarUrl);
      const chat_ = new Chat(
        getTranslatedName(
          serverResponse.name,
          serverResponse.tName,
          language ??
            serverResponse.history?.language ??
            serverResponse.language
        ),
        {
          defaultMinNextPromptDelayMs:
            serverResponse.settings.chatDefaultDelayMs ??
            MIN_NEXT_PROMPT_DELAY_MS,
          onHistoryItemWillBeInserted: (item, prompt) => {
            if (item.direction === "sent" && prompt.custom?.feedback) {
              item.custom = { feedback: true };
            }
          },
          onHistoryChanged: (history) => {
            const lastItem = history[history.length - 1];
            if (
              updateToken.current != null &&
              lastItem !== null &&
              lastItem.direction === "received" &&
              // Don't save for auto prompts, otherwise it leads to many save
              // requests happening really fast, which can subsequently lead to
              // the updateToken getting out of sync.
              lastItem.promptAnswerType !== "auto"
            ) {
              updateManagedChat(
                id,
                identifier,
                chat_.toJson(),
                updateToken.current
              )
                .then((response) => {
                  updateToken.current = response.updateToken;
                })
                .catch((e) => {
                  if (e.response.status === 409) {
                    window.alert(
                      "This page needs a refresh to stay up to date." +
                        "\nPress 'OK' to continue."
                    );
                    window.location.reload();
                  }
                });
            }
          },
          onPromptAdded: (prompt) => {
            if (prompt.isTerminal) {
              postChatEndedMessage(id, chat_.totalScore);
            }
          },
          confirmDestructiveAlteration: () =>
            new Promise((resolve) => {
              if (
                window.confirm(
                  "Progress after this point in the chat will be lost." +
                    "\nAre you sure you want to go ahead?"
                )
              ) {
                resolve(true);
              } else {
                resolve(false);
              }
            }),
          language:
            language ??
            serverResponse.history?.language ??
            serverResponse.language,
          context: {
            userId: identifier,
            args: {
              originId: [id],
            },
          },
        }
      );
      if (settings.disableAutoAnswer) {
        chat_.isAutoAnswerEnabled = false;
      }
      setChat(chat_);
      updateToken.current = serverResponse.updateToken;
      if (serverResponse.history != null) {
        await chat_.loadFromJson(serverResponse.history);
      } else {
        await chat_.setPrompt(startPromptKey(id, serverResponse.version));
      }
    }
  }

  useEffect(() => {
    if (settings.dryRun) {
      initDryRunChat();
    } else {
      console.log("Initiating live run");
      initLiveRunChat();
    }

    return () => chat?.dispose();
  }, [_id, identifier, location.search]);

  if (chat == null) return null;

  return (
    <div className={styles.root}>
      {settings.showName && <div className={styles.title}>{chat.name}</div>}
      <div className={styles.chatView}>
        <ChatView
          chat={chat}
          showScore={settings.showScore}
          disableKeyboardAnswer={settings.disableKeyboardAnswer}
          onChatStateError={console.log}
          renderMaxScore={(maxScore) =>
            maxScore != null && maxScore > 0 ? maxScore.toFixed(0) : null
          }
          $baseCssVariables={$baseCssVariables.current}
          avatarSrc={avatarUrl}
        />
      </div>
    </div>
  );
}
