import {
  AnyChatFlowDefinition,
  ChatFlow,
  ChatFlowDefinition,
  flowFactory,
  PromptKey,
} from "@xflr6/chatbot";
import {
  appendVersionsToRemoteNextKeys,
  setAnswerSamplesBuilder,
  setAnswerStatsBuilder,
  setMeetingBuilder,
} from "@xflr6/chatbot_customizations";
import {
  answerSamplesUrlPattern,
  answerStatsUrlPattern,
  getPublishedFlow,
} from "@xflr6/chatbot-api";

export const startPromptKey = (
  chatId: number | string,
  version?: number | string | null
): PromptKey => {
  const args = new URLSearchParams({
    chatId: `${chatId}`,
    ...(version != null ? { version: `${version}` } : {}),
  }).toString();
  return PromptKey.fromString(`remote?${args}.start`);
};

export class RemoteFlow extends ChatFlow {
  async getDefinition(): Promise<AnyChatFlowDefinition> {
    const chatId = (this.id.args["chatId"] ?? [])[0];
    const version = (this.id.args["version"] ?? [])[0];
    // TODO Dry-run mode won't work here because we force a non-null version!
    if (chatId == null || version == null) {
      throw new Error("Valid flow id and/or version not found in arguments");
    } else {
      return (await getPublishedFlow(chatId, version, this.chat.language))
        .definition;
    }
  }

  async onDefinitionAvailable(definition: ChatFlowDefinition): Promise<void> {
    await appendVersionsToRemoteNextKeys(definition);
  }

  async onDefinitionProcessed(definition: ChatFlowDefinition): Promise<void> {
    setAnswerSamplesBuilder(this, definition, answerSamplesUrlPattern);
    setAnswerStatsBuilder(this, definition, answerStatsUrlPattern);
    setMeetingBuilder(this, definition);
  }
}

export function setupFlowFactory(): void {
  flowFactory.pushFactoryFunc(
    /remote.*/,
    (flowId, chat) => new RemoteFlow(flowId, chat)
  );
}
