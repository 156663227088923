import { BaseCssVariablesStream } from "@xflr6/chatbot";
import { SettingsPatch } from "@xflr6/chatbot-api";
import { nullifyIfBlank } from "@xflr6/utils";

interface ChatSettings {
  showScore: boolean;
  disableKeyboardAnswer: boolean;
  disableAutoAnswer: boolean;
  showName: boolean;
  dryRun: boolean;
}

interface ChatSequenceSettings {
  showScore: boolean;
  allowRandomAccess: boolean;
  disableKeyboardAnswer: boolean;
  disableAutoAnswer: boolean;
  showName: boolean;
  dryRun: boolean;
}

const DEFAULT_CHAT_SETTINGS: ChatSettings = {
  showScore: false,
  disableKeyboardAnswer: false,
  disableAutoAnswer: false,
  showName: false,
  dryRun: false,
};

const DEFAULT_CHAT_SEQUENCE_SETTINGS: ChatSequenceSettings = {
  showScore: false,
  allowRandomAccess: false,
  disableKeyboardAnswer: false,
  disableAutoAnswer: false,
  showName: false,
  dryRun: false,
};

function extractSettingsFromQueryString<T>(qs: string, defaultSettings: T): T {
  const queryParams = new URLSearchParams(qs);
  const settingsBase64 = queryParams.get("s") ?? queryParams.get("settings");
  if (settingsBase64 == null) return defaultSettings;
  try {
    const settingsJSONString = atob(settingsBase64);
    const settings = JSON.parse(settingsJSONString);
    return Object.assign(defaultSettings as any, settings);
  } catch (e) {
    console.log(e);
    return defaultSettings;
  }
}

export const extractChatSettings = (qs: string): ChatSettings =>
  extractSettingsFromQueryString(qs, DEFAULT_CHAT_SETTINGS);

export const extractChatSequenceSettings = (qs: string): ChatSequenceSettings =>
  extractSettingsFromQueryString(qs, DEFAULT_CHAT_SEQUENCE_SETTINGS);

export const MIN_NEXT_PROMPT_DELAY_MS = 1000;

export const extractIdentifierType = (qs: string): string | null => {
  const queryParams = new URLSearchParams(qs);
  return queryParams.get("t") ?? queryParams.get("type") ?? null;
};

export const extractLanguage = (qs: string): string | null => {
  const queryParams = new URLSearchParams(qs);
  return queryParams.get("l") ?? queryParams.get("language") ?? null;
};

export function applyThemeSettings(
  $baseCssVariables: BaseCssVariablesStream,
  settings: SettingsPatch
): void {
  $baseCssVariables.next({
    "--primary": settings.chatPrimaryColor ?? null,
    "--accent": settings.chatAccentColor ?? null,
    "--borderRadius": settings.chatBorderRadius ?? null,
    "--spacing": settings.chatSpacing ?? null,
  });
}

export function getTranslatedName(
  name: string,
  tName: Record<string, string> | null | undefined,
  language: string | null | undefined
): string {
  return language ? nullifyIfBlank(tName?.[language]) ?? name : name;
}
