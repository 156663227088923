import "./App.css";

import React, { ReactElement } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import ChatSeqViewPage from "./pages/chatSeqView/ChatSeqViewPage";
import ChatViewPage from "./pages/chatView/ChatViewPage";

function App(): ReactElement {
  return (
    <div className="App">
      <Router>
        <Switch>
          <Route path="/chats/:id/:identifier?">
            <ChatViewPage />
          </Route>
          <Route path="/chat_sequences/:id/:identifier?">
            <ChatSeqViewPage />
          </Route>
          <Route
            path="/"
            render={() => <div>This is the chatbot embed app</div>}
          />
        </Switch>
      </Router>
    </div>
  );
}

export default App;
